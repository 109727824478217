.mapControlContainer {
  position: absolute;
  right: 48px;
  bottom: 79px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  z-index: 500;
}

.mapControlContainer.smallPadding {
  right: 12px;
  bottom: 46px;
}

.mapControlButton {
  width: 36px;
}
