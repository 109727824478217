@import "../../defaultTheme.css";

.addTreeConfirmationModal {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addTreeConfirmationModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 320px;
  height: 100%;
}

.addTreeConfirmationTitle {
  padding-left: 5px;
  font: var(--font-type-25);
}

.locationPresenterContainer {
  position: relative;
  width: 320px;
  height: 221px;
}

.informationSummaryContainer {
  box-sizing: border-box;
  padding: 8px;
  width: 320px;
  border: 2px solid var(--primary-turquoise);
  border-radius: 4px;
}

.informationSummaryContainer2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  align-self: stretch;
}

.informationSummaryLatSpecies {
  font: var(--font-type-16-bold);
}

.informationSummarySecondaryInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
  width: 100%;
  font: var(--font-type-16);
}

.informationSummarySecondaryRight {
  max-width: 50%;
  word-break: break-word;
  text-align: right;
}

.informationSummarySecondaryRight.noSensor {
  width: 100%;
  max-width: 100%;
  text-align: right;
}

.informationSummaryTreeNumber {
  font: var(--font-type-13-light);
}

.informationSummaryButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.informationSummaryErrorContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--error-red);
}
