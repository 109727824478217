@import "../../defaultTheme.css";

.tableScrollContainer {
  overflow-y: auto;
  max-height: 404px;
  overflow-x: auto;
}

.tableScrollContainer::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.tableScrollContainer::-webkit-scrollbar-track {
  position: absolute;
  width: 12px;
  height: 12px;
  margin-top: 14px;
  margin-left: 14px;
  background: #ffffff;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  border: 1px solid transparent;
  background-clip: padding-box;
}

.tableScrollContainer::-webkit-scrollbar-thumb {
  width: 14px;
  height: 14px;
  background: var(--primary-turquoise);
  border-radius: 4px;
}

.tableContainer {
  margin-right: 16px;
  margin-left: 16px;
  justify-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
}

.tableColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-right: 16px;
  min-width: 0;
}

.tableHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0px;
  width: 100%;
  height: 51px;
  gap: 12px;
  min-width: 0;
  overflow: hidden;
}

.tableHeader > button {
  padding: 0;
}

.tableCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 49px;
  gap: 12px;

  /* min-width: 0; */
}

.tableCell button {
  box-sizing: initial;
  text-align: left;
}

.tableHeaderContent {
  padding: 0px 16px 0px 2px;
  height: 24px;
  font: var(--font-type-16-bold);
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 18px);
  display: flex;
  gap: 12px;
  align-items: center;
}

.tableCellContent {
  padding: 0px 16px 0px 2px;
  height: 24px;
  font: var(--font-type-16);
  letter-spacing: 0.0002em;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 18px);
}

button.tableCellContent {
  color: var(--primary-turquoise);
}

.tableInputContainer {
  display: inline-block;
  background-color: #e06c00;
}

.tableInputContainerWrapper {
  display: flex;
  flex-direction: column;
}

input.tableCellContent {
  border: none;
  outline: none;
  display: block;
  font: var(--font-type-16);
  background: #e9e9e9;
  box-sizing: border-box;
  text-overflow: clip;
}

input.tableCellContent + .tableCellLine {
  background: var(--primary-turquoise);
}

.tableCellContent.redColor {
  color: white;
  background-color: #e32126;
}

.tableCellContent.yellowColor {
  background-color: #fff353;
}

.tableCellContent.greenColor {
  color: white;
  background-color: #019646;
}

.tableCellContent.lightBlueColor {
  color: white;
  background-color: #6b96ca;
}

.tableCellContent.darkBlueColor {
  color: white;
  background-color: #172f70;
}

.cellHover:hover {
  background: #e9e9e9;
}

button.tableCellContent {
  text-decoration-line: underline;
  background-color: inherit;
  border: inherit;
}

.tableCellContent.imageContainer {
  width: 77px;
  padding: 0;
  text-align: center;
}

.tableHeaderLine {
  width: 100%;
  height: 2px;
  background: var(--primary-turquoise);
}

.tableCellLine {
  width: 100%;
  height: 1px;
  background: #000000;
}
