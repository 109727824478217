@import "../../defaultTheme.css";

.floatingButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 100%;
  height: 100%;
  background: var(--primary-turquoise);
  border-radius: 100%;
}

.floatingButtonContainer.ignoreRounding {
  border-radius: 0;
  padding: 0;
}

.floatingButtonContainer.withoutBackground {
  background: none;
}

.floatingButtonIcon {
  width: 100%;
  height: 100%;
}

.floatingButtonIcon.greyOut {
  opacity: 29%;
}
