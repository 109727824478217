@import "../../defaultTheme.css";

.navBarContainer {
  position: absolute;
  z-index: 1100;
  width: 64%;
  height: 56px;
  left: 18%;
  top: 0px;
}

.navBarBackground {
  position: absolute;
  z-index: 501;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: var(--primary-turquoise);
  border-radius: 0px 0px 35px 10px;
}

.navBarContent {
  z-index: 502;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  position: relative;
  width: calc(100% - 62px);
  height: 35px;
  left: 32px;
  top: 11px;
}

.navBarText {
  height: 35px;
  font: var(--font-type-25-bold);
  color: rgba(255, 255, 255, 0.55);
}

.navBarWateringImage {
  margin-left: auto;
}

.navBarText.selected {
  color: rgb(255, 255, 255);
}

.navBarDivider {
  width: 0px;
  height: 34px;
  border-left: 3px solid #ffffff;
  border-radius: 3px;
}

#navigationBarDragStart {
  cursor: move;
}

.navDragTargets {
  position: fixed;
  width: 37px;
  height: 53px;
}

#navFakeDragImage {
  position: fixed;
  height: 36px;
  top: -100px;
  z-index: 1200;
  pointer-events: none;
  transition: rotate 200ms;
}

#navFakeDragImage.targeting {
  rotate: 60deg;
}
