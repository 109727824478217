@import "../../defaultTheme.css";

.inputWrapper {
  position: relative;
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  align-self: stretch;

  padding: 4px 0px 8px;
  position: relative;
  align-self: stretch;
}

.inputFieldLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 20px;
  font: var(--font-type-13-light);
  align-self: stretch;
}

.inputFieldLabel.hidden {
  opacity: 0;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 4px;
  z-index: 1;
  width: 100%;
  height: 22px;
}

.labelTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.labelText {
  display: flex;
  align-items: center;
  color: var(--light-ui-secondary);
}

.labelTextOptional {
  width: 72px;
  height: 18px;
  font: var(--font-type-13-light);
  display: flex;
  align-items: center;
  color: var(--light-ui-tertiary);
}

.labelImageContainer {
  width: 12px;
  height: 12px;
}

.inputFieldContainer {
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inputField {
  width: 100%;
  border: none;
  outline: none;
  font: var(--font-type-16);
  display: flex;
  align-items: center;
  color: var(--light-ui-secondary);
}

.inputField.edited {
  font: var(--font-type-16-italic-medium);
}

.inputUnderline {
  width: 100%;
  height: 0px;
  flex: none;
  align-self: stretch;
  margin-bottom: 1px;
  border-bottom: 1px solid var(--primary-turquoise);
}

.inputUnderline.focused {
  border-bottom-width: 2px;
  margin-bottom: 0px;
}

.inputAmendment {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 12px;
  gap: 8px;
  font: var(--font-type-13-light);
  min-height: 18px;
}

.hintText {
  color: var(--light-ui-tertiary);
}

.errorTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  word-break: break-word;
}

.errorIcon {
  width: 12px;
  height: 12px;
  filter: brightness(0) saturate(100%) invert(16%) sepia(86%) saturate(3826%)
    hue-rotate(348deg) brightness(87%) contrast(91%);
  /* TODO this should probably be done differently (separate svg?)  */
}

.errorText {
  color: var(--error-red);
}

.autoCompletionContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;
  max-height: 126px;
  top: 62px;
  z-index: 5;
  overflow: scroll;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.suggestionLine {
  height: 22px;
  width: 100%;
  flex-wrap: nowrap;
  font: var(--font-type-16);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.suggestionText {
  white-space: nowrap;
}

.suggestionText.match {
  color: var(--primary-turquoise);
}
