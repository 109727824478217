@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans/NotoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans/NotoSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans/NotoSans-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans/NotoSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans/NotoSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

:root {
  --primary-turquoise: #31afb4;
  --light-ui-tertiary: #666666;
  --light-ui-secondary: #333333;
  --error-red: #cf2024;
  --font-type-31: normal 500 31px/140% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 140%; */
  --font-type-25: normal 400 25px/140% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 140%; */
  --font-type-25-semi-bold: normal 600 25px/140% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%; */
  --font-type-25-bold: normal 700 25px/140% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 140%; */
  --font-type-20-extralight: normal 200 20px/140% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 140%; */
  --font-type-16-bold: normal 700 16px/140% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px; */
  --font-type-16: normal 400 16px/150% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; */
  --font-type-16-italic-medium: italic 500 16px/140% "Noto Sans";
  /* font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%; */
  --font-type-14: normal 400 14px/140% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%; */
  --font-type-13-light: normal 300 13px/140% "Noto Sans";
  /* font-family: "Noto Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 140%; */
}

button {
  background: none;
  border: none;
  cursor: pointer;
}
