@import "../../defaultTheme.css";

.addTreeModal {
  position: absolute;
  width: 64%;
  height: 500px;
  left: 18%;
  bottom: 0;
  z-index: 1050;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
}

.closeButtonContainer {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  transform: translate(50%, -50%);
  z-index: 10;
}

.leaveAddTree {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  left: 32px;
  top: 18px;
}

.addTreeTitle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 28px;
  left: 68px;
  top: 16px;
  font: var(--font-type-20-extralight);
}

.deleteTree {
  padding: 0;
  width: 16px;
  height: 16px;
}

.addTreeInput {
  position: absolute;
  width: 340px;
  left: 32px;
  top: 64px;
  text-align: center;
}

.addTreeInputRowContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
}

#addTreeInputSensor {
  width: 193px;
}

#addTreeInputSoil {
  width: 164px;
}

.addTreeButton {
  display: inline-block;
}

.locationSelector {
  position: absolute;
  top: 32px;
  right: 32px;
}
