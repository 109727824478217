.mapPanel {
  width: 100vw;
  height: 100vh;
}

.blurMapOverlay {
  width: 100vw;
  height: 100vh;
  z-index: 1005;
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}
