@import "../../defaultTheme.css";

.locationSelectorPanel {
  width: 436px;
  height: 436px;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
}

.locationSelectorMapPanel {
  width: 100%;
  height: calc(100% - 30px);
}

.locationSelectorTextBar {
  position: absolute;
  width: 100%;
  height: 30px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px;
}

.textBarElement {
  width: 128px;
  height: 24px;
}

.latLongLabel {
  height: 24px;
  font: var(--font-type-16-bold);
  line-height: 24px;
  letter-spacing: 0.0002em;
  margin-right: 12px;
}

.latLongInput {
  width: calc(100% - 39px - 12px);
  height: 24px;
  font: var(--font-type-16);
  letter-spacing: 0.0002em;
  border: none;
  color: var(--primary-turquoise);
}

.latLongInput.edited {
  width: calc(100% - 39px - 12px - 2px);
  font: var(--font-type-16-italic-medium);
}

.latLongInput::placeholder {
  color: var(--primary-turquoise);
}
