@import "../../defaultTheme.css";

.popUpBlurOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1150;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}

.popupContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #ffffff;
  border-radius: 5px;
  width: 377px;
}

.popupTitle {
  font: var(--font-type-25);
  padding-bottom: 4px;
  color: #000000;
}

.popupLine {
  width: 241px;
  height: 2px;
  left: 24px;
  top: 63px;
  background: var(--error-red);
}

.popupText {
  padding-top: 16px;
  font: var(--font-type-16);
  color: #000000;
}

.popupButtonContainer {
  padding-top: 32px;
  display: flex;
  gap: 32px;
}
