@import "../../defaultTheme.css";

.loginModalBlurOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1200;
}

.loginModalContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 40px 30px;
  gap: 10px;
  background: #ffffff;
  border-radius: 0px 26px 17px 73px;
  right: 18%;
  top: 56px;
  width: 291px;
}

.loginModalContainer.logoutModal {
  padding: 29px 30px;
}

.loginModalContainer.expandedView {
  width: 1000px;
  border-radius: 10px;
}

.loginInfoContainer {
  width: 550px;
  height: 423px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.loginInfoHeader {
  width: 550px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  font: var(--font-type-31);
}

.loginInfoText {
  width: 540px;
  font: var(--font-type-16);
}

.loginInfoGraphicContainer {
  margin-top: 34px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 38px;
}

.loginInfoDivider {
  width: 4px;
  height: 423px;
  border-radius: 4px;
  margin-right: 81px;
  background-color: var(--primary-turquoise);
}

.loginInputsContainer {
  width: 299px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
}

.loginInputsContainer.expandedView {
  padding: 0px;
}

.loginInputHeader {
  align-self: flex-start;
  color: var(--primary-turquoise);
  font: var(--font-type-25);
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-bottom: 32px;
}

.errorMessageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 0px;
  gap: 10px;
  word-break: break-word;
  font: var(--font-type-13-light);
  padding-bottom: 24px;
  color: var(--error-red);
}

.errorIcon {
  width: 12px;
  height: 12px;
  filter: brightness(0) saturate(100%) invert(16%) sepia(86%) saturate(3826%)
    hue-rotate(348deg) brightness(87%) contrast(91%);
  /* TODO this should probably be done differently (separate svg?)  */
}

.loginSwitchContainer {
  align-self: flex-start;
}
