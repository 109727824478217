@import "../../defaultTheme.css";

.listModal {
  position: absolute;
  width: 64%;
  height: 500px;
  left: 18%;
  bottom: 0;
  z-index: 1050;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
}

.closeButtonContainer {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  transform: translate(50%, -50%);
  z-index: 10;
}

.listModalHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 32px;
  width: calc(100% - 64px);
}

.listModalHeaderTabs {
  display: flex;
  gap: 35px;
}

.listModalHeaderTab {
  font: var(--font-type-31);
  color: rgba(0, 0, 0, 0.25);
}

.listModalHeaderTab.selected {
  color: #000000;
}

.listModalHeaderButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.listModalTableContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 16px;
  width: calc(100% - 32px);
}

.listModalWateringEmptyMessage {
  height: 400px;
  display: flex;
  align-items: center;
}

.listModalWateringEmptyMessage > div {
  font: var(--font-type-25);
  color: rgba(0, 0, 0, 0.25);
}
