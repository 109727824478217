@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/static/OpenSans/OpenSans-Regular.ttf")
    format("truetype");
  font-weight: 400;
}

.footerContainer {
  position: absolute;
  right: 48px;
  bottom: 33px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 24px;
  z-index: 900;
  background-color: #ffffff;
  border-radius: 5px;
}

.footerContent {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #000000;
}
