/*parent adds margin of 14px 25px 14px 21px*/
.markerContainer {
  position: fixed;
  z-index: 750;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 41px;
  width: 280px;
  height: 540px;
  gap: 10px;
  background-color: white;
  border-radius: 10px 35px 0px 0px;
  cursor: default;
}

#markerCloseButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  transform: translate(8px, -3px);
  z-index: 10;
}

.markerTitle {
  font: var(--font-type-25-semi-bold);
}

.markerTabs {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  gap: 38px;
  padding: 15px 0;
}

.markerTabs > button {
  width: calc(50% - 19px);
  text-align: left;
  padding: 7px 0 0;
  font: var(--font-type-16-bold);
  color: rgba(0, 0, 0, 0.25);
  transition: color 150ms;
}

.markerTabs > button.active {
  color: black;
}

.markerTabLine {
  position: absolute;
  left: 0px;
  bottom: 14px;
  width: 80px;
  height: 1px;
  background: var(--primary-turquoise);
  transition: left 150ms;
}

.markerTabLine.details {
  left: calc(50% + 19px);
}

.markerBody {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.markerText {
  font: var(--font-type-16);
}

.markerTable {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(7, 22px);
  column-gap: 38px;
  row-gap: 18px;
}

.markerTable > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: var(--font-type-16);
}

.markerTable > span:nth-child(odd) {
  font-weight: bold;
}

.markerTable > svg {
  align-self: center;
}

.markerPopupButton {
  margin-top: auto;
  /* margin-top: 33px; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.markerPopupCircle {
  width: 126px;
  height: 126px;
  border-radius: 100%;
}
