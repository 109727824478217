@import "../../defaultTheme.css";

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 18px;
  height: 40px;
  outline: none;
  background: var(--primary-turquoise);
  color: #f3f3f3;
  border: 1px solid transparent;
  border-radius: 28px;
}

.buttonContainer.tertiary {
  padding: 12px 32px;
  height: 40px;
  top: 195px;
  background: transparent;
  border: none;
  color: var(--primary-turquoise);
}

.buttonContainer:hover {
  background: #267e82;
}

.buttonContainer.tertiary:hover {
  background: transparent;
  color: #59adb0;
}

.buttonContainer:focus {
  background: var(--primary-turquoise);
  border: 1px solid #57d8dd;
}

.buttonContainer.tertiary:focus {
  background: transparent;
  color: #57d8dd;
  border: none;
}

.buttonContainer:active {
  background: linear-gradient(
    269.47deg,
    var(--primary-turquoise) 0.45%,
    #49bdc2 12.76%,
    #90e3e7 40.72%,
    #47bcc1 81.8%,
    var(--primary-turquoise) 99.64%
  );
}

.buttonContainer.tertiary:active:enabled {
  background: linear-gradient(
    269.47deg,
    var(--primary-turquoise) 0.45%,
    #49bdc2 12.76%,
    #90e3e7 40.72%,
    #47bcc1 81.8%,
    var(--primary-turquoise) 99.64%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.buttonContainer:disabled {
  background: #5b5b5b;
}

.buttonContainer.tertiary:disabled {
  background: transparent;
  color: #5b5b5b;
}

.buttonIcon {
  width: 24px;
  height: 24px;
}

.buttonText {
  height: 19px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}
