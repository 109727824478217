@import "../../defaultTheme.css";

.locationPresenterPanel {
  width: 100%;
  height: 100%;
}

.locationPresenterMapPanel {
  position: absolute;
  width: 100%;
  height: calc(100% - 24px);
}

.locationPresenterTextBar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 72px;
  position: absolute;
  bottom: 0;
}

.presenterTextBarElement {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  width: 128px;
}

.presenterLatLongLabel {
  font: var(--font-type-16-bold);
  letter-spacing: 0.0002em;
}

.presenterLatLongValue {
  width: calc(100% - 39px - 12px);
  overflow: hidden;
  font: var(--font-type-16);
  letter-spacing: 0.0002em;
  color: var(--primary-turquoise);
}
